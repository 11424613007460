import React from 'react';
import { useCart } from '../../../provider/Cart/CartProvider';
import Icons from '../../../../public/assets/images/icons';
import { Flex } from '../../Flex';
export const CartButton = (): JSX.Element => {
  const { state, setIsOpen } = useCart();
  return (
    <button className="w-fit h-full py-3 rounded-md " onClick={setIsOpen}>
      <Flex
        align="center"
        className="gap-3 text-primary-pure rounded-full border-[1px] border-pure-Op10 p-1"
      >
        <Flex className="rounded-full p-2 border-[1px] bg-primary-pureOp10 border-pure-Op10">
          <Icons.Cart className="fill-none stroke-primary-pure" />
        </Flex>
        <span className="font-bold pr-4">{state.items.length}</span>
      </Flex>
    </button>
  );
};
