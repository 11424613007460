import React, { useContext, useEffect, useRef, useState } from 'react';
import { Flex } from '../../Flex';
import { Avatar } from '../../Avatar';
import { AuthContext } from '../../../provider/Auth/AuthContext';
import Icons from '../../../../public/assets/images/icons';
import { useRouter } from 'next/router';

export const ProfileButton = (): JSX.Element => {
  const { user } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { logout } = useContext(AuthContext);
  const containerRef = useRef<HTMLButtonElement>(null);
  const router = useRouter();

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <button ref={containerRef} className="w-fit h-full p-3 rounded-md">
      <Flex
        align="center"
        onClick={() => setIsOpen((oldState) => !oldState)}
        className="gap-3 text-primary-pure rounded-full relative shadow-small border-[1px] border-pure-Op10 p-1 pr-2"
      >
        <Avatar userName={user?.first_name || ''} key={user?.first_name} />
        <p className=" text-neutral-pure600 text-sm">Meu perfil</p>
        <Icons.ChevronDown className="text-neutral-pure700" />
        {isOpen && (
          <Flex
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="cursor-pointer shadow-medium hidden lg:flex absolute z-[100] top-[50px] right-0 w-[273px] bg-white shadow-small rounded-md flex-col gap-2 p-2"
          >
            <Flex
              className="p-4 gap-2 text-[16px] font-[600] text-neutral-pure700 border-b-[1px] border-b-neutral-pure300/25"
              align="center"
              onClick={() => {
                router.push(`/meus-dados`);
              }}
              justify="between"
            >
              <Flex className="gap-2">
                <Icons.User className="text-primary-pure" />
                <span>Minha conta</span>
              </Flex>
              <Icons.ChevronRight />
            </Flex>
            <Flex
              className="p-4 gap-2 text-[16px] font-[600] text-neutral-pure700 border-b-[1px] border-b-neutral-pure300/25"
              align="center"
              onClick={() => {
                router.push(`/pedidos`);
              }}
              justify="between"
            >
              <Flex className="gap-2">
                <Icons.Box className="text-primary-pure" />
                <span>Meus pedidos</span>
              </Flex>
              <Icons.ChevronRight />
            </Flex>
            <Flex
              className="p-4 gap-2 text-[16px] font-[600] text-neutral-pure700"
              align="center"
              onClick={logout}
              justify="between"
            >
              <Flex className="gap-2">
                <Icons.Leave className="text-error-pure" />
                <span>Sair da conta</span>
              </Flex>
              <Icons.ChevronRight />
            </Flex>
          </Flex>
        )}
      </Flex>
    </button>
  );
};
