import Link from 'next/link';
import { Flex } from '../Flex';
import { useContext } from 'react';
import { AuthContext } from '../../provider/Auth/AuthContext';
import { SmallSlider } from '../Slider';
import Icons from '../../../public/assets/images/icons';
import { useRouter } from 'next/router';

const NavigationMenu = () => {
  const { categories } = useContext(AuthContext);
  const router = useRouter();
  const basketsStyle =
    router.pathname === '/'
      ? 'cursor-pointer p-1 px-2 h-fit rounded-xl bg-success-pureOp10'
      : 'cursor-pointer p-1 px-2 h-fit rounded-xl bg-primary-pureOp10';
  return (
    <Flex
      align="center"
      className="h-full
      w-full
        text-neutral-pure700 relative "
    >
      <Flex
        className="overflow-hidden gap-1 h-full w-full font-[600] text-neutral-pure600 text-xs xl:text-sm 2xl:text-base"
        align="center"
        justify="between"
      >
        <Flex className="w-fit h-full" align="center">
          <Flex align="center" className={basketsStyle}>
            <Link href="/">
              <a>
                <Flex
                  className="text-neutral-pure1000 gap-2 h-fit"
                  align="center"
                >
                  <Icons.Basket
                    className={
                      router.pathname === '/'
                        ? 'text-success-pure text-base'
                        : 'text-primary-pure text-base'
                    }
                  />
                  Recomendações de cesta
                </Flex>
              </a>
            </Link>
          </Flex>
        </Flex>
        <div className="divider divider-horizontal w-[1px] p-0" />
        <Flex className=" w-fit h-full">
          <Flex align="center" className="cursor-pointer">
            <Link href="/categorias">
              <a>
                <Flex className="gap-4" align="center">
                  Todas as categorias
                </Flex>
              </a>
            </Link>
          </Flex>
        </Flex>
        <SmallSlider
          className="h-full w-[55%] xl:w-[65%]"
          options={{ dragFree: true }}
        >
          <Flex className="gap-2 xl:gap-8 2xl:gap-10 h-full">
            {categories.map((category) => {
              return (
                <Flex align="center" className="gap-2" key={category.id}>
                  <Flex
                    align="center"
                    justify="center"
                    onClick={() => {
                      router.push(`/ofertas/${category.description}`);
                    }}
                    className="h-full cursor-pointer w-[180px] xl:w-[200px] 2xl:w-[220px]"
                  >
                    <a>
                      {category.description.charAt(0).toUpperCase() +
                        category.description.slice(1).toLowerCase()}
                    </a>
                  </Flex>
                </Flex>
              );
            })}
          </Flex>
        </SmallSlider>
        <Flex className="w-fit">
          <Icons.ChevronRight className="h-6 w-6 text-neutral-pure600" />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default NavigationMenu;
