import React from 'react';
import { Flex } from '../Flex';
import Logo from '../Logo';
import { CompanySwitcher } from './CompanySwitcher';
import { BurgerMenu } from './BurgerMenu';
import { Sidebar } from './Sidebar';
import NavigationMenu from './NavigationMenu';
import { CartButton } from './Buttons/CartButton';
import ProductsSearchInput from './Search/ProductsSearch';
import { ProfileButton } from './Buttons/ProfileButton';

export const Header = (): JSX.Element => {
  return (
    <>
      <Flex className="shadow-xl sticky top-0 z-[1000] invisible hidden lg:visible lg:block">
        <nav className="bg-white flex items-center h-[80px] justify-center  py-4 border-b-neutral-border border-b-[1px]">
          <Flex
            align="center"
            justify="between"
            className="w-full max-w-[1600px] px-[5%] 2xl:px-[2%] gap-4"
          >
            <Flex className="w-fit justify-center">
              <Logo url="/" alternative="default" />
            </Flex>
            <Flex className="w-[15%] min-w-[200px] pr-8 justify-center">
              <CompanySwitcher />
            </Flex>
            <Flex className="w-[50%]" justify="center">
              <ProductsSearchInput />
            </Flex>
            <Flex className="2xl:w-[15%] w-[20%] justify-center items-center gap-2 min-w-[151px]">
              <ProfileButton />
            </Flex>
            <Flex className="w-[10%] justify-end">
              <CartButton />
            </Flex>
          </Flex>
        </nav>
        <Flex
          justify="center"
          align="center"
          className="bg-white w-full text-primary-pure"
        >
          <Flex className="w-full h-12 max-w-[1600px] px-[5%] 2xl:px-[2%]">
            <NavigationMenu />
          </Flex>
        </Flex>
      </Flex>
      <Flex
        className="   
        drawer   
      shadow-xl
       sticky
       top-0
       z-[10000]
       lg:invisible
       lg:hidden"
      >
        <input id="my-drawer" type="checkbox" className="drawer-toggle" />
        <nav className="bg-white drawer-content w-full flex items-center justify-center md:px-4">
          <Flex direction="col" className="flex-grow relative top-0 p-4 gap-4">
            <Flex
              align="center"
              justify="center"
              className={`transition-all relative`}
            >
              <Flex className="w-fit justify-center">
                <Logo url="/" alternative="bicolor" />
              </Flex>
              <span className=" absolute right-[-8px]">
                <CartButton />
              </span>
              <span className="text-primary-pure absolute left-0 bg-primary-pure10 p-2 rounded-full">
                <label htmlFor="my-drawer">
                  <BurgerMenu />
                </label>
              </span>
            </Flex>
            <Flex align="center">
              <ProductsSearchInput />
            </Flex>
          </Flex>
        </nav>
        <Sidebar />
      </Flex>
    </>
  );
};
