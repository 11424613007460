import React, { useContext, useState } from 'react';
import { Flex } from '../Flex';
import { AuthContext } from '../../provider/Auth/AuthContext';
import { Modal } from '../Modal';
import { useModal } from '../../utils/hooks/useModal';
import { Company } from '../../interfaces/User';
import Icons from '../../../public/assets/images/icons';

export const CompanySwitcher = (): JSX.Element => {
  const { user, selectedCompany, handleSetSelectedCompany } =
    useContext(AuthContext);
  const [selected, setSelected] = useState<Company | null>(selectedCompany);

  const { openModal, closeModal } = useModal({
    modalId: 'companySwitcher'
  });

  const handleConfirm = () => {
    handleSetSelectedCompany(selected);
    closeModal();
  };

  const handleCancel = () => {
    handleSetSelectedCompany(selectedCompany);
    closeModal();
  };

  return (
    <>
      <Flex align="center" className="gap-1">
        <Flex
          style={{ boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.06)' }}
          className="rounded-full border-[1px] p-1 border-neutral-pure500 w-fit h-fit"
          align="center"
          justify="center"
        >
          <Icons.Bread />
        </Flex>
        <Flex direction="col" className="relative">
          <p className="font-semibold text-sm text-neutral-400 absolute left-[17px] top-4">
            Estabelecimento
          </p>
          <select
            value={selectedCompany?.id}
            onChange={(e) => {
              const selectedOption = user?.companies?.find(
                (company) => company.id === Number(e.target.value)
              );
              if (selectedOption) {
                setSelected(selectedOption);
                openModal();
              }
            }}
            className="select w-[100%] mt-5 py-0 text-md select-md text-neutral-1000 font-bold focus:outline-none"
          >
            {user?.companies?.map((company) => (
              <option key={company.id} value={company.id}>
                {company.trade_name}
              </option>
            ))}
          </select>
        </Flex>
      </Flex>
      <Modal
        id="companySwitcher"
        title="Quer fazer o pedido em outra padaria?"
        maxSize="xl"
        buttons={{
          confirm: {
            content: 'Mudar de Padaria',
            variant: 'primary',
            callBack: handleConfirm
          },
          cancel: {
            content: 'Continuar na Atual',
            variant: 'secondary',
            callBack: handleCancel,
            outline: true
          }
        }}
      >
        <p className="font-normal text-neutral-700">
          Não se preocupe, os produtos adicionados ao carrinho continuarão
          salvos nesse perfil para que você navegue livremente entre suas
          padarias.
        </p>
      </Modal>
    </>
  );
};
