import React, { useContext } from 'react';
import { AuthContext } from '../../../provider/Auth/AuthContext';
import { Flex } from '../../Flex';
import Icons from '../../../../public/assets/images/icons';

export const LogoutButton = (): JSX.Element => {
  const { logout } = useContext(AuthContext);
  return (
    <button onClick={logout}>
      <Flex
        className="border-b-[1px]  px-6 py-4 gap-2 font-[600] text-neutral-pure700"
        align="center"
      >
        <span>Sair</span>
        <Icons.ArrowRight className="w-[1rem] h-[1rem]" />
      </Flex>
    </button>
  );
};
