import { AxiosPaginatingResponse } from '../../../interfaces/Axios';
import { Offer } from '../../../interfaces/Offer';
import { api } from '../../axios/auth';

export const getOffers = async ({
  companyId,
  token,
  params = {
    limit: 10
  },
  nextUrl
}: {
  companyId: number;
  token?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params?: { [key: string]: any };
  nextUrl?: string;
}): Promise<AxiosPaginatingResponse<Offer> | Offer[]> => {
  const apiUrl = nextUrl || `/offer/?client=${companyId}&active=true`;
  const response = await api.get<AxiosPaginatingResponse<Offer> | Offer[]>(
    apiUrl,
    {
      headers: {
        Authorization: `Bearer ${token}`
      },
      params
    }
  );

  return response.data;
};
