import React from 'react';
import { Flex } from '../../Flex';
import Logo from '../../Logo';
import Icons from '../../../../public/assets/images/icons';
import { useRouter } from 'next/router';

export const CartHeader = (): JSX.Element => {
  const router = useRouter();

  const dolarOuterBg = router.pathname.includes('pagamento')
    ? 'bg-white rounded-full p-2'
    : 'bg-[rgba(255,255,255,0.1)] rounded-full p-2';
  const dolarColor = router.pathname.includes('pagamento')
    ? 'w-5 h-5 text-primary-pure '
    : 'w-5 h-5 text-white opacity-[0.7]';
  const dolarText = router.pathname.includes('pagamento')
    ? 'text-white font-[600]'
    : 'text-white opacity-[0.7] font-[600]';

  const checkOuterBg = router.pathname.includes('finalizado')
    ? 'bg-white rounded-full p-2'
    : 'bg-[rgba(255,255,255,0.1)] rounded-full p-2';
  const checkColor = router.pathname.includes('finalizado')
    ? 'w-5 h-5 text-primary-pure'
    : 'w-5 h-5 text-white opacity-[0.7]';
  const checkText = router.pathname.includes('finalizado')
    ? 'text-white font-[600]'
    : 'text-white opacity-[0.7] font-[600]';
  return (
    <>
      <Flex className="shadow-xl sticky top-0 z-[1000] w-full lg:visible lg:block">
        <nav className="bg-primary-pure w-full px-2 flex items-center h-[80px] justify-center md:px-4 py-4 border-b-neutral-border border-b-[1px]">
          <Flex
            align="center"
            className="justify-center lg:justify-between w-[100%] max-w-[1200px] gap-4"
          >
            <Flex className="w-fit justify-center">
              <Logo url="/" alternative="white" />
            </Flex>
            <Flex className="gap-4 hidden lg:flex" align="center">
              <Flex className="gap-3" align="center">
                <Flex className="rounded-full p-2 bg-white text-primary-pure">
                  <Icons.Cart />
                </Flex>
                <p className="text-white font-[600] text-[16px]">
                  Meu Carrinho
                </p>
              </Flex>
              <Icons.ChevronRight className="opacity-[0.4]" />
              <Flex className="gap-3" align="center">
                <Flex className={dolarOuterBg}>
                  <Icons.Dolar className={dolarColor} />
                </Flex>
                <p className={dolarText}>Pagamento</p>
              </Flex>
              <Icons.ChevronRight className="opacity-[0.4]" />
              <Flex className="gap-3" align="center">
                <Flex className={checkOuterBg}>
                  <Icons.CartCheck className={checkColor} />
                </Flex>
                <p className={checkText}>Finalizado!</p>
              </Flex>
            </Flex>
            <Flex align="center" className="gap-2">
              <Flex className="bg-[rgba(255,255,255,0.1)] rounded-full p-2">
                <Icons.Shield />
              </Flex>
              <p className="text-white opacity-[0.7] font-[600]">
                Ambiente seguro!
              </p>
            </Flex>
          </Flex>
        </nav>
      </Flex>
    </>
  );
};
