import { useContext, useState } from 'react';
import debounce from 'lodash/debounce';
import { useRouter } from 'next/router';
import { useCombobox } from 'downshift';
import { Offer } from '../../../interfaces/Offer';
import { Flex } from '../../Flex';
import { getOffers } from '../../../http/queries/offer/getOffers';
import { token } from '../../../http/queries/token';
import { AxiosPaginatingResponse } from '../../../interfaces/Axios';
import { AuthContext } from '../../../provider/Auth/AuthContext';
import Icons from '../../../../public/assets/images/icons';
import { Button } from '../../Button';
import { HorizontalOfferItem } from '../../../features/Offer/HorizontalOfferItem';
import { useHandleCart } from '../../../utils/hooks/useHandleCart';

const debouncedSearch = debounce(
  async (query: string, companyId: number, setInputItems) => {
    const response = await getOffers({
      companyId,
      token: token(),
      params: { query, limit: 12 }
    });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    setInputItems(response);
  },
  300
);

const ProductsSearchInput = () => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const { selectedCompany } = useContext(AuthContext);
  const [inputItems, setInputItems] =
    useState<AxiosPaginatingResponse<Offer>>();
  const router = useRouter();

  const { handleAddProductToCart } = useHandleCart(false);

  const handleSearch = (query: string) => {
    if (selectedCompany)
      debouncedSearch(query, selectedCompany.id, setInputItems);
  };

  const {
    inputValue,
    isOpen,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    selectedItem
  } = useCombobox({
    items: inputItems?.results || [],
    isOpen: menuIsOpen,
    onStateChange: (changes) => {
      switch (changes.type) {
        case useCombobox.stateChangeTypes.InputBlur:
          setMenuIsOpen(false);
          break;
        case useCombobox.stateChangeTypes.ItemClick:
          setMenuIsOpen(false);
          break;
        case useCombobox.stateChangeTypes.InputChange:
          setMenuIsOpen(true);
          break;
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
          setMenuIsOpen(false);
          break;
      }
    },
    itemToString: (item) =>
      `${item?.commercial_product.base_product?.description}` || '',
    onInputValueChange: ({ inputValue: inputChange }) => {
      if (inputChange) handleSearch(inputChange);
    },
    onSelectedItemChange: ({ selectedItem: selectedChangeItem }) => {
      router.push(`/ofertas/oferta/${selectedChangeItem?.id}`);
    }
  });

  return (
    <div className="w-full relative">
      <Flex className="relative" align="center">
        <input
          {...getInputProps()}
          className="input  input-md w-full rounded-md  bg-neutral-pure200"
          placeholder="O que você está procurando?"
          onKeyDown={(e) => {
            if (e.key === 'Enter' && inputValue)
              router.push(`/ofertas?search=${inputValue}`);
          }}
        />
        <Flex
          onClick={() => {
            router.push(`/ofertas?search=${inputValue}`);
          }}
          className="hidden lg:flex absolute cursor-pointer right-0 pr-3 stroke-primary-pure fill-primary-pure text-primary-pure"
        >
          <Icons.Search />
        </Flex>
        <Flex className="lg:hidden absolute cursor-pointer right-0 pr-3 stroke-primary-pure fill-primary-pure text-primary-pure">
          <Button
            onClick={() => {
              router.push(`/ofertas?search=${inputValue}`);
            }}
            className="bg-primary-pureOp10 hover:bg-primary-pure10 border-none text-primary-pure w-fit normal-case"
            size="sm"
          >
            Buscar{' '}
          </Button>
        </Flex>
      </Flex>
      <ul
        className={`w-full absolute input input-md bg-white mt-1 shadow-md h-fit max-h-[200px] overflow-x-hidden p-0 z-10 ${
          !(isOpen && inputItems?.results.length) && 'hidden'
        }`}
        {...getMenuProps()}
      >
        {isOpen &&
          inputItems?.results?.map((item, index) => (
            <li
              className={`py-2 px-3 shadow-sm flex flex-col cursor-pointer  ${
                highlightedIndex === index ? 'bg-blue-300' : ''
              } ${selectedItem === item ? 'font-bold' : ''}`}
              key={`${item.id}${index}${item.commercial_product.base_product?.description}`}
              {...getItemProps({ item, index })}
            >
              <HorizontalOfferItem
                handleAdd={(offerToAdd, value) =>
                  handleAddProductToCart(offerToAdd as Offer, value)
                }
                offer={item}
                isSearch
                key={item.id}
              />
            </li>
          ))}
      </ul>
    </div>
  );
};

export default ProductsSearchInput;
