/* eslint-disable jsx-a11y/label-has-associated-control */
import { useContext } from 'react';
import { Flex } from '../Flex';
import { AuthContext } from '../../provider/Auth/AuthContext';
import { Avatar } from '../Avatar';
import Link from 'next/link';
import Icons from '../../../public/assets/images/icons';
import { useRouter } from 'next/router';
import { LogoutButton } from './Buttons/LogoutButton';
import { MobileCompanySwitcher } from './MobileCompanySwitcher';

export const Sidebar = (): JSX.Element => {
  const { user, categories } = useContext(AuthContext);
  const router = useRouter();
  return (
    <div className="drawer-side h-full">
      <label htmlFor="my-drawer" className="drawer-overlay" draggable={false} />
      <ul className="h-full menu bg-neutral-pure200  w-80 max-w-[65%] min-h-full px-0 text-base-content flex flex-col justify-between">
        <Flex className="gap-2 pt-12 h-full" direction="col">
          <Flex className="w-full px-6 pb-7 gap-2" align="center">
            <Avatar userName={user?.first_name || ''} />
            <p className="text-neutral-600">
              <strong>{user?.first_name}</strong>
            </p>
          </Flex>
          <Flex className="bg-white px-6">
            <MobileCompanySwitcher />
          </Flex>
          <Flex
            className="bg-white px-6 py-4"
            onClick={() => {
              document.getElementById('my-drawer')?.click();
            }}
          >
            <Link href="/">
              <a>
                <Flex
                  className="gap-2 text-neutral-pure700 font-[600]"
                  align="center"
                >
                  <Icons.Basket className="text-primary-pure text-base" />
                  Recomendações de cesta
                </Flex>
              </a>
            </Link>
          </Flex>
          <Flex
            className="bg-white px-6 py-4"
            onClick={() => {
              document.getElementById('my-drawer')?.click();
            }}
          >
            <Link href="/pedidos">
              <a>
                <Flex
                  className="gap-2  text-neutral-pure700 font-[600]"
                  align="center"
                >
                  <Icons.Box className="text-primary-pure text-base" />
                  Acompanhe seus pedidos
                </Flex>
              </a>
            </Link>
          </Flex>
          <Flex direction="col" className="bg-white h-full" justify="between">
            <Flex direction="col" className="bg-white">
              <Flex
                className="border-b-[1px]  px-6 py-4"
                onClick={() => {
                  document.getElementById('my-drawer')?.click();
                }}
              >
                <Link href="/categorias">
                  <a>
                    <Flex
                      className="gap-2 text-primary-pure font-[600]"
                      align="center"
                    >
                      Todas as categorias
                    </Flex>
                  </a>
                </Link>
              </Flex>
              {categories.map((category, index) => {
                if (index > 5) return;
                return (
                  <Flex
                    onClick={() => {
                      router.push(`/ofertas/${category.description}`);
                      document.getElementById('my-drawer')?.click();
                    }}
                    className="border-b-[1px]  px-6 py-4"
                    key={category.id}
                  >
                    <Flex
                      className="gap-2 text-neutral-pure700 font-[600]"
                      align="center"
                    >
                      {category.description.charAt(0).toUpperCase() +
                        category.description.slice(1).toLowerCase()}
                    </Flex>
                  </Flex>
                );
              })}
            </Flex>
            <LogoutButton />
          </Flex>
        </Flex>
      </ul>
    </div>
  );
};
