import React from 'react';
import { Header } from '../Header';
import PageMeta from '../PageMeta';
import { Flex } from '../Flex';
import Footer from '../Footer';
import { useRouter } from 'next/router';
import { CartHeader } from '../Header/Cart/CartHeader';
interface Props {
  children: React.ReactNode;
  subtitle?: string;
  align?: 'center' | 'start' | 'end';
  isHome?: boolean;
}
export const Layout = ({
  children,
  subtitle = 'Pedidos',
  align = 'center',
  isHome = false
}: Props): JSX.Element => {
  const router = useRouter();
  return (
    <>
      <PageMeta subtitle={subtitle} />
      <div className="w-full min-h-full  bg-neutral-pure200">
        {router.pathname.includes('carrinho') ? <CartHeader /> : <Header />}
        <Flex
          className={
            router.pathname.includes('carrinho')
              ? 'min-h-[calc(100vh-357px)] w-full mb-8'
              : 'min-h-[calc(100vh-407px)] w-full mb-8'
          }
          justify="center"
          align={align}
        >
          <Flex
            className={
              isHome
                ? 'h-full w-full'
                : 'h-full w-full max-w-[1600px] px-4 lg:px-0'
            }
          >
            {children}
          </Flex>
        </Flex>
        <Footer />
      </div>
    </>
  );
};
