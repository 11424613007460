import Link from 'next/link';

import { Flex } from '../Flex';
import Icons from '../../../public/assets/images/icons';

const Footer = () => {
  return (
    <footer className="static bottom-0 z-50 overflow-hidden">
      <Flex direction="col">
        <Flex
          direction="col"
          align="center"
          justify="center"
          className="relative bg-primary-pure text-white py-10 px-4"
        >
          <div className="w-full">
            <Flex
              className="gap-6 flex-col lg:flex-row px-4 lg:px-0"
              justify="evenly"
            >
              <div>
                <Flex className="gap-4 flex-col lg:flex-row">
                  <Flex className="bg-white rounded-full w-fit h-fit p-2 shadow-footerLogo">
                    <Icons.Phone className="text-primary-pure" />
                  </Flex>
                  <Flex direction="col" className="gap-1">
                    <h4 className="font-[600] text-[14px] text-neutral-300">
                      Precisa de ajuda? Fale conosco
                    </h4>
                    <p className="font-[400] text-[32px]">+55 27 99639-3006</p>
                    <p className="font-[600] text-[14px] text-neutral-300">
                      AV. Jerônimo Monteiro, 1000 – Vitória, ES – 29.010-002
                    </p>
                    <p className="font-[600] text-[14px] text-neutral-300 ">
                      © 2022 Rede de Tecnologia para Padarias LTDA.
                      40.079.793/0001-09
                    </p>
                  </Flex>
                </Flex>
              </div>
              <div>
                <Flex direction="col" className="gap-4">
                  <h4 className="font-[400] text-[20px] text-white">
                    Links úteis
                  </h4>
                  <Flex
                    direction="col"
                    className="gap-3 font-[600] text-[14px] text-neutral-300 z-[100]"
                  >
                    <Link href="https://bakerytech.com.br/#faq" target="_blank">
                      <a>Perguntas Frequentes</a>
                    </Link>
                    <Link
                      href="https://bakerytech.com.br/termos-de-uso/"
                      target="_blank"
                    >
                      <a>Termos de uso</a>
                    </Link>
                    <Link
                      href="https://bakerytech.com.br/privacy-policy/"
                      target="_blank"
                    >
                      <a>Políticas de privacidade</a>
                    </Link>
                  </Flex>
                </Flex>
              </div>
              <div>
                <Flex direction="col" className="gap-4">
                  <h4 className="font-[400] text-[20px] text-white">
                    Nossas redes sociais
                  </h4>
                  <Flex align="center" className="gap-2 z-[100]">
                    <Flex
                      justify="center"
                      align="center"
                      className="w-8 h-8 p-2 rounded-md border-[1px] border-neutral-pure600 bg-neutral-pureOp10"
                    >
                      <Link
                        href="https://www.instagram.com/bakerytechbr/"
                        target="_blank"
                      >
                        <a>
                          <Icons.Instagram alt="instagram" />
                        </a>
                      </Link>
                    </Flex>
                    <Flex
                      justify="center"
                      align="center"
                      className="w-8 h-8 p-2 rounded-md border-[1px] border-neutral-pure600 bg-neutral-pureOp10"
                    >
                      <Link
                        href="https://www.linkedin.com/company/bakery-tech"
                        target="_blank"
                      >
                        <a>
                          <Icons.Linkedin alt="Linkedin" />
                        </a>
                      </Link>
                    </Flex>
                  </Flex>
                </Flex>
              </div>
            </Flex>
          </div>
          <Icons.LogoLeafTopRight className="block absolute top-0 left-[35px] w-[215px] h-[300px]" />
          <Icons.LogoLeafTopLeft className="hidden xl:block absolute top-0 left-[900px] w-[508px] h-[254px]" />
          <Icons.LogoLeafRightTop className="hidden xl:block absolute top-0 right-[-170px] w-[508px] h-[254px]" />
          <Icons.LogoLeafRightBottom className="block absolute bottom-[-20px] right-[-180px] w-[508px] h-[254px]" />
        </Flex>
        <Flex
          className="gap-4 z-[2] bg-white py-1 text-neutral-pure700 font-[600] text-[14px]"
          align="center"
          justify="center"
        >
          <Icons.SmallLogo className="w-20 mt-1" />© todos os direitos
          reservados
        </Flex>
      </Flex>
    </footer>
  );
};

export default Footer;
